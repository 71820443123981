@use 'scss/base/variables' as *;

.accountsCard {
  margin-bottom: 0;
  padding: 0;
}

.top,
.bottom {
  padding: 20px;
}

.top {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 0;
  }

  a {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;

    &:hover {
      text-decoration: none;

      i {
        color: $link-color;
      }
    }
  }

  i {
    color: get($gray, 600);
    font-size: 2rem;
    transition: color 0.2s ease-in-out;
  }
}

.accountsTable {
  margin-bottom: 15px;

  th:nth-child(2),
  td:nth-child(2) {
    text-align: right;
  }

  td:last-child {
    text-align: right;
    width: 45px;

    i {
      transition: color 0.2s ease-in-out;
    }
  }
}

.accountName {
  transition: color 0.2s ease-in-out;
}

.accountDetails {
  color: get($gray, 500);
}

.rowLink {
  &:hover {
    .accountName,
    i {
      color: $link-color;
    }
  }
}

.newAccount {
  i {
    margin-right: 5px;
  }
}
