@use 'scss/base/variables' as *;

.account {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 30px;
  padding-bottom: 30px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

.accountDescription {
  float: left;
  font-size: 1.6rem;
  margin: 0;

  @media (max-width: 575px) {
    float: none;
  }
}

.accountName {
  color: get($gray, 900);
  font-weight: 600;
}

.accountType {
  display: block;
  font-weight: 400;
  margin-top: 10px;
  text-transform: uppercase;
}

.accountRight {
  float: right;
  text-align: right;

  @media (max-width: 575px) {
    clear: left;
    float: none;
    margin-top: 10px;
    text-align: left;
  }
}

.accountStatus {
  display: block;
  font-weight: 500;
  margin-bottom: 3px;
}

.viewAccount {
  @media (max-width: 575px) {
    display: inline-block;
    margin-top: 20px;
  }
}
