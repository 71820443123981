.preferenceCopyWrap {
  display: flex;

  @media (max-width: 991px) {
    display: block;
  }

  a {
    flex-shrink: 0;
    margin-left: 60px;

    @media (max-width: 991px) {
      margin: 20px 0 0;
    }

    @media (max-width: 360px) {
      padding: 13px 20px;
    }
  }
}
