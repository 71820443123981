@use 'scss/base/variables' as *;

.table {
  table-layout: fixed;

  @media (max-width: 991px) {
    table-layout: auto;
  }

  th {
    padding: 15px 0 20px;

    &:first-child {
      width: 35%;
    }

    &:nth-child(2) {
      width: 12%;

      @media (min-width: 1200px) {
        padding-right: 60px;
        text-align: right;
      }
    }

    &:nth-child(3) {
      width: 12%;
    }

    &:nth-child(4) {
      width: 28%;
    }
  }

  td {
    border-top: 1px solid get($gray, 200);
    padding: 25px 25px 25px 0;

    @media (max-width: 1199px) {
      border-top: 0;
      padding: 10px 15px;
    }

    &:nth-child(2) {
      @media (min-width: 1200px) {
        padding-right: 60px;
        text-align: right;
      }
    }
  }
}

.offeringName {
  color: get($gray, 900);
  font-size: 1.6rem;
  line-height: 1.2;
}

.entityName {
  color: get($gray, 500);
  text-transform: uppercase;
}

.activityLink {
  display: inline-block;
  line-height: 1.3;
  margin-top: 5px;
}

.stepWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 74px;
}

.stepNumber {
  font-weight: 500;
  margin-bottom: 5px;
}

.stepMessage {
  line-height: 1.4;
}

.alertSpan,
.notificationSpan {
  position: relative;

  &::before {
    font-family: FontAwesome;
    left: -20px;
    line-height: 1.7;
    position: absolute;

    @media (max-width: 1199px) {
      margin-right: 8px;
      position: static;
    }
  }
}

.alertSpan {
  &::before {
    color: get($poppy, 500);
    content: '\f071';
    line-height: 1.7;
  }
}

.notificationSpan {
  position: relative;

  &::before {
    color: get($summit, 900);
    content: '\f111';
    font-size: 1rem;
    line-height: 2.2;
  }
}
