@use 'scss/base/variables' as *;

.devicesTable {
  border: 1px solid get($gray, 200);
  width: 100%;

  thead {
    th {
      color: get($gray, 600);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.8;
      min-height: 56px;
      padding: 15px;
      text-align: left;

      &.indicator {
        padding: 0;
      }
    }
  }

  tr {
    border: 1px solid get($gray, 200);
  }

  td {
    font-size: 1.4rem;
    font-weight: 400;
    padding: 40px 15px;
    vertical-align: bottom;

    &.indicator {
      padding: 0;
      width: 6px;
    }

    h4 {
      margin-top: 0;
    }
  }
}

.tableHeader {
  color: get($gray, 600);
  margin-top: 10px;
}

.current {
  td:first-child {
    background-color: get($summit, 900);
    border-top: 1px solid get($summit, 900);
  }

  .deviceIcon img {
    opacity: 1;
  }
}

.currentDevice {
  color: get($summit, 900);
  text-transform: uppercase;
}

.removeDevice {
  button {
    font-size: 1.1rem;
    height: auto;
    padding: 8px 15px;

    @media (max-width: 479px) {
      margin: 10px 0 0;
    }
  }
}

.lastSeen {
  white-space: nowrap;

  @media (max-width: 768px) {
    display: block;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.dash {
  @media (max-width: 768px) {
    display: none;
  }
}

.deviceIcon {
  padding-top: 50px;
  text-align: right;
  vertical-align: baseline;

  img {
    height: 32px;
    opacity: 0.4;
  }
}

.largeDisplay {
  @media (max-width: 479px) {
    display: none;
  }
}

.mobileDisplay {
  display: none;

  @media (max-width: 479px) {
    display: block;
  }
}
