@use 'scss/base/variables' as *;

// Still some legacy stuff in this file to be cleaned up once ERBs are removed

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

body {
  background-color: #f8f8f8;
  font-size: 1.4rem !important;
}

.content-wrapper:not(:empty) {
  padding: 60px 0;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

// Used on pages that don't need the 60px of top and bottom padding that .content-wrapper applies
.noHeaderWrap {
  margin: -60px 0 !important;
  padding: 20px 0;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
}

button {
  appearance: none;
  background: none;
  border: 0;
  box-shadow: none;
  line-height: 1.4;
  outline: none !important;
  padding: 0;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
}

#wrap {
  clear: both;
  height: auto;
  margin: 0 auto;
  min-height: 100%;
}

// Misc

.clearfix {
  clear: both;
}

.clearfix-after::after {
  clear: both;
  content: '';
  display: block;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.fitContent {
  width: fit-content;
}

.overflow-visible {
  overflow: visible !important;
}

.description {
  color: get($gray, 600);
  font-size: 1.4rem;
  line-height: 1.8;
}

*:focus {
  outline: none;
}

.container-wide {
  @media (min-width: 1440px) {
    max-width: 1410px !important;
  }
}

.container-medium {
  width: 1030px;

  @media (max-width: 575px) {
    max-width: 100%;
  }
}

.container-narrow {
  margin: 0 auto;
  max-width: 100% !important;
  padding: 0 15px;
  width: 830px;
}

.container-sidebar {
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 100% !important;
  padding: 0 15px;
  width: 830px;

  @media (max-width: 1199px) and (min-width: 1024px) {
    display: flex;
    max-width: 1010px !important;
    width: auto;
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    max-width: 750px !important;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  .card {
    @media (max-width: 1199px) {
      margin-left: auto;
      margin-right: 0;
      max-width: 800px;
      width: 100%;
    }
  }
}

.hidden-small {
  @media (max-width: 767px) {
    display: none;
  }
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}

.margin-right-x {
  margin-right: 15px;
}

.margin-right-xx {
  margin-right: 30px;
}

.margin-x {
  margin-bottom: 15px;
}

.margin-xx {
  margin-bottom: 30px;
}

.margin-xxx {
  margin-bottom: 45px;
}

.margin-xxxx {
  margin-bottom: 60px;
}

.margin-100 {
  margin-bottom: 100px;
}

.margin-top-x {
  margin-top: 15px;
}

.margin-top-xx {
  margin-top: 30px;
}

.margin-top-xxx {
  margin-top: 45px;
}

.margin-top-xxxx {
  margin-top: 60px;
}

.margin-0 {
  margin: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-row {
  margin-bottom: 15px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.padding0 {
  padding: 0;
}

.padding20 {
  padding: 20px;
}

.inline-block {
  display: inline-block;
}

.position-relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.button-wrap {
  display: flex;
  justify-content: flex-end;

  button:last-child {
    margin-left: 15px;
  }
}

.forwardBackButtonWrap {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media (max-width: 400px) {
    flex-direction: column;
  }

  a:first-child,
  button:first-child {
    @media (max-width: 400px) {
      width: 100% !important;
    }
  }

  a:last-child,
  button:last-child {
    @media (max-width: 400px) {
      margin-top: 20px;
    }
  }
}

.forwardBackButtonWrapCompact {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 40px;
}

.embed-responsive {
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}
