@use 'scss/base/variables' as *;

.menuContainer {
  border-bottom: 1px solid #eee;
  display: flex;
  padding-bottom: 20px;
}

.tabsMenu {
  list-style: none;
  padding: 0;

  li {
    cursor: pointer;
    float: left;
    font-size: 1.6rem;
    margin-right: 80px;

    @media (max-width: 767px) {
      margin-right: 30px;
    }

    @media (max-width: 479px) {
      float: none;
      margin: 0 0 30px;
    }

    &:last-child {
      margin: 0;
    }

    a {
      color: get($gray, 500);
      cursor: pointer;
      font-size: 1.6rem;

      &:global(.active) {
        color: get($summit, 900);
        font-weight: 500;
      }
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
