@use 'scss/base/variables' as *;

.cards {
  border-left: 1px solid get($gray, 200);
  padding-left: 15px;

  @media (max-width: 991px) {
    border-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: -15px;
    padding-left: 0;
  }

  @media (max-width: 767px) {
    margin: -15px auto 0;
    max-width: 100%;
    width: 345px;
  }
}

.card {
  background: #fff;
  border: 1px solid get($gray, 200);
  border-radius: 20px;
  display: block;
  margin-bottom: 16px;
  padding: 10px 16px;
  word-wrap: break-word;

  @media (max-width: 991px) {
    flex-grow: 0;
    margin-bottom: 0;
    width: calc(50% - 7.5px);
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  h6 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    transition: color 0.2s ease-in-out;
  }
}

.label {
  color: get($summit, 700);
  font-size: 1.2rem;
  font-weight: 600;
}
