.container {
  min-height: 100vh;
}

.contentWrap {
  display: flex;
  gap: 15px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.mainContent {
  width: 83%;

  @media (min-width: 1200px) {
    max-width: 1170px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.sidebar {
  flex-shrink: 0;
  width: 17%;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.heading {
  margin: 0;

  @media (max-width: 575px) {
    font-size: 3.2rem;
  }
}

.sectionSubheading {
  margin: 10px 0 30px;
}
